import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IoCloseOutline, IoCallSharp } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";

import styles from './Header.module.scss'
import logo from "../../assets/logo/KoreanLogo-reversal.png";
import phoneNumberImage from "../../assets/images/PhoneNumber.png";

export const Header = ({ pathName }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 980px)' });
	const isRedBox = useMediaQuery({ query: '(max-width: 1120px)' });
	const [isHeaderVisible, setIsHeaderVisible] = useState(false); // 모바일에서 헤더 메뉴를 보여줄지 여부
	const [path, setPath] = useState(pathName);
	const phoneNumber = '1533-8848';

	const handleNavigate = pathName => {
		setIsHeaderVisible(false);
		if (pathName === '') window.location.href = '/';
		else window.location.href = pathName;
	};

	return (
		<>
			{isMobile ? (
				<header className={styles.header}>
					<Link to='/'>
						<img className={styles.logo} src={logo} alt="posco 평택 푸르지오 센터파인" />
					</Link>

					<div className={styles.iconBox}>
						<a href={`tel:${phoneNumber}`}>
							<IoCallSharp className={styles.callIcon} color="#FFFFFF" />
						</a>
						<AiOutlineMenu className={styles.menuIcon} color="#FFFFFF" onClick={() => setIsHeaderVisible(!isHeaderVisible)} />
					</div>

					{isHeaderVisible && (
						<div className={styles.overlay}>
							<IoCloseOutline
								onClick={() => setIsHeaderVisible(false)}
								className={styles.closeButton}
								color="#FFFFFF"
								size={25}
							/>
							<ul className={styles.list}>
								<Link to='/' >
									<li className={styles.item}>HOME</li>
								</Link>
								<Link to='/BusinessGuide'>
									<li className={styles.item}>사업안내</li>
								</Link>
								<Link to='/LocationEnvironment'>
									<li className={styles.item}>입지환경</li>
								</Link>
								<Link to='/Premium'>
									<li className={styles.item}>프리미엄</li>
								</Link>
								<Link to='/GuideComplex'>
									<li className={styles.item}>단지안내</li>
								</Link>
								<Link to='/EquilibriumGuide'>
									<li className={styles.item}>평형안내</li>
								</Link>
							</ul>
						</div>
					)}
				</header>
			) : (
				<header className={styles.header}>
					<Link to='/'>
						<img className={styles.logo} src={logo} alt="posco 평택 푸르지오 센터파인" />
					</Link>

					<div className={styles.menu} >
						<Link to='/' className={[path === undefined ? styles.boldLink : styles.link]}>
							<div>HOME</div>
						</Link>
						<Link to='/BusinessGuide' className={[path === '/BusinessGuide' ? styles.boldLink : styles.link]}>
							<div>사업안내</div>
						</Link>
						<Link to='/LocationEnvironment' className={[path === '/LocationEnvironment' ? styles.boldLink : styles.link]}>
							<div>입지환경</div>
						</Link>
						<Link to='/Premium' className={[path === '/Premium' ? styles.boldLink : styles.link]}>
							<div>프리미엄</div>
						</Link>
						<Link to='/GuideComplex' className={[path === '/GuideComplex' ? styles.boldLink : styles.link]}>
							<div>단지안내</div>
						</Link>
						<Link to='/EquilibriumGuide' className={[path === '/EquilibriumGuide' ? styles.boldLink : styles.link]}>
							<div>평형안내</div>
						</Link>
					</div>

					<a href='https://naver.me/FWfnlWCx' target='_blank'>
						<img className={styles.phoneNumber} src={phoneNumberImage} alt="상담문의 1533-8848" />
					</a>
				</header >
			)}
		</>

	)
}