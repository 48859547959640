import React from "react";
import styles from './TitleBox.module.scss'
import equilibriumGuideImage from '../../assets/images/EquilibriumGuideImage.png';
import locationEnvironmentImage from '../../assets/images/LocationEnvironmentImage.png';
import premiumImage from '../../assets/images/PremiumImage.png';

export const TitleBox = ({ title }) => {
    const titleText = title

    if (titleText === "입지환경") {
        return <img className={styles.bener} src={locationEnvironmentImage} alt="평택 푸르지오 센터파인 개요" />
    } else if (titleText === "프리미엄") {
        return <img className={styles.bener} src={premiumImage} alt="입지장점" />
    } else {
        return <img className={styles.bener} src={equilibriumGuideImage} alt="타입별 내용" />
    }
}